import { PageLoading } from '@Estoca/ui';
import { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

const DashboardHome = lazy(() =>
  import('../pages/DashboardHome').then(module => ({
    default: module.DashboardHome,
  }))
);

export const Routes = () => (
  <Suspense fallback={<PageLoading />}>
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path={['/', '/home', '/dashboard']}
          component={DashboardHome}
        />
      </Switch>
    </BrowserRouter>
  </Suspense>
);
