import { type ContextType } from '../../../context/DashboardDataContext';

export type WarningMapperData = {
  title: string;
  description: string;
  totalQuantity: number;
  apiUrl: string;
  isClickable?: boolean;
};

export type WarningMapperType = {
  context: ContextType;
  data: WarningMapperData[];
};

export const WarningMapper: WarningMapperType[] = [
  {
    context: 'inbound',
    data: [
      {
        title: 'Remessa com divergência',
        description: 'Saldo divergente durante conferência',
        totalQuantity: 0,
        apiUrl: '/divergence',
        isClickable: true,
      },
      {
        title: 'Itens bloqueados',
        description: 'Itens recebidos com avarias',
        totalQuantity: 0,
        apiUrl: '/damaged-itens',
        isClickable: true,
      },
    ],
  },
  {
    context: 'outbound',
    data: [
      {
        title: 'Pedidos devolvidos',
        description: 'Pedidos retornados pelo cliente final',
        totalQuantity: 0,
        apiUrl: '/returned-orders',
      },
      {
        title: 'Pedidos extraviados',
        description: 'Extravios sinalizados pela transportadora',
        totalQuantity: 0,
        apiUrl: '/delivery-error',
      },
      {
        title: 'Quarentena',
        description: 'Pedidos parados no fluxo operacional do armazém',
        totalQuantity: 0,
        apiUrl: '/quarantine',
      },
    ],
  },
];
