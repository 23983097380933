import { type ContextType } from '../../../context/DashboardDataContext';

export type TimelineMapperData = {
  title: string;
  ref: string;
  icon: string;
  value: number;
  color: string;
  tooltip?: string;
  isClickable?: boolean;
  date: string[];
};

export type TimelineMapperType = {
  context: ContextType;
  data: TimelineMapperData[];
};

export const TimelineMapper: TimelineMapperType[] = [
  {
    context: 'inbound',
    data: [
      {
        title: 'Enviado',
        ref: 'sent',
        icon: 'uploadIconWhite.svg',
        value: 0,
        color: 'default',
        tooltip: 'Não serão considerados itens devolvidos',
        date: ['now', 'month'],
      },
      {
        title: 'Recebido',
        ref: 'received',
        icon: 'downloadIconWhite.svg',
        value: 0,
        color: 'default',
        date: ['now', 'month'],
      },
      {
        title: 'Check-in',
        ref: 'checkin',
        icon: 'syncIconWhite.svg',
        value: 0,
        color: 'default',
        date: ['now'],
      },
      {
        title: 'Conferência parcial',
        ref: 'partial_check',
        icon: 'pizzaIconWhite.svg',
        isClickable: true,
        value: 0,
        color: 'default',
        date: ['now', 'month'],
      },
      {
        title: 'Conferido',
        ref: 'checked',
        icon: 'searchIconWhite.svg',
        value: 0,
        color: 'default',
        tooltip: 'Não serão considerados itens devolvidos',
        date: ['now', 'month'],
      },
      {
        title: 'Armazenando',
        ref: 'storing',
        icon: 'syncIconWhite.svg',
        value: 0,
        color: 'default',
        date: ['now'],
      },
      {
        title: 'Atenção',
        ref: 'warning',
        icon: 'warningIconWhite.svg',
        value: 0,
        color: 'warning',
        date: ['now', 'month'],
      },
      {
        title: 'Itens armazenados',
        ref: 'stored',
        icon: 'checkIconWhite.svg',
        value: 0,
        color: 'success',
        date: ['now', 'month'],
      },
    ],
  },
  {
    context: 'outbound',
    data: [
      {
        title: 'Aguardando liberação',
        ref: 'waiting_labels',
        icon: 'bookmarkIconWhite.svg',
        value: 0,
        color: 'default',
        date: ['now'],
      },
      {
        title: 'Novos pedidos',
        ref: 'holded',
        icon: 'plusIconWhite.svg',
        value: 0,
        color: 'default',
        date: ['now'],
      },
      {
        title: 'Pedidos recebidos',
        ref: 'received',
        icon: 'bookmarkIconWhite.svg',
        value: 0,
        color: 'default',
        date: ['month'],
      },
      {
        title: 'Separando agora',
        ref: 'picking',
        icon: 'syncIconWhite.svg',
        value: 0,
        color: 'default',
        date: ['now'],
      },
      {
        title: 'Pedidos separados',
        ref: 'picked',
        icon: 'layersIconWhite.svg',
        value: 0,
        color: 'default',
        date: ['now', 'month'],
      },
      {
        title: 'Empacotando agora',
        ref: 'packing',
        icon: 'syncIconWhite.svg',
        value: 0,
        color: 'default',
        date: ['now'],
      },
      {
        title: 'Pedidos empacotados',
        ref: 'packed',
        icon: 'syncIconWhite.svg',
        value: 0,
        color: 'default',
        date: ['month'],
      },
      {
        title: 'Em transito',
        ref: 'transit',
        icon: 'carIconWhite.svg',
        value: 0,
        color: 'default',
        date: ['month'],
      },
      {
        title: 'Atenção',
        ref: 'warning',
        icon: 'warningIconWhite.svg',
        value: 0,
        color: 'warning',
        date: ['now', 'month'],
      },
      {
        title: 'Pedidos despachados',
        ref: 'dispatched',
        icon: 'checkIconWhite.svg',
        value: 0,
        color: 'success',
        date: ['now'],
      },
      {
        title: 'Pedidos entregues',
        ref: 'delivered',
        icon: 'checkIconWhite.svg',
        value: 0,
        color: 'success',
        date: ['month'],
      },
    ],
  },
];
