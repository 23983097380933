import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { DashboardDataContextProvider } from './context/DashboardDataContext';
import { DatesRangeContextProvider } from './context/DatesRangeContext';
import {
  ProfileDataContextProvider,
  type ProfileData,
} from './context/ProfileDataContext';
import { Routes } from './routes';
import { GlobalStyles } from './styles/global';
import { TOAST_DEFAULT_PROPS } from './utils/constants';

export interface DashboardAppProps {
  profileData: ProfileData | null;
}

export const DashboardApp = ({ profileData }: DashboardAppProps) => {
  return (
    <ProfileDataContextProvider profileData={profileData}>
      <DatesRangeContextProvider>
        <DashboardDataContextProvider>
          <GlobalStyles />

          <ToastContainer {...TOAST_DEFAULT_PROPS} />
          <Routes />
        </DashboardDataContextProvider>
      </DatesRangeContextProvider>
    </ProfileDataContextProvider>
  );
};
