import { globalCss } from '@Estoca/ui';

export const GlobalStyles = globalCss({
  fontFamily: 'Open Sans',
  '.tooltipContainer': {
    position: 'relative',
    '&:hover .tooltip': {
      visibility: 'visible',
    },
  },
});
