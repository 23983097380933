/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-prototype-builtins */
/* eslint-disable eqeqeq */
import axios, { AxiosResponse } from 'axios';
import { useEffect, useState, type ReactNode } from 'react';
import { toast } from 'react-toastify';
import { createContext, useContextSelector } from 'use-context-selector';

import {
  TimelineMapper,
  type TimelineMapperData,
} from '../components/TabContent/Timeline/TimelineMapper';
import {
  WarningMapper,
  type WarningMapperData,
} from '../components/TabContent/Warning/WarningMapper';
import { getStartAndEndDateBeingToday } from '../helpers/date';
import { HTTP_RESPONSES } from '../helpers/responses';
import { ERROR_MESSAGES } from '../helpers/toast-messages';
import { apiBaseURL } from '../lib/api';

import { DatesRangeContext } from './DatesRangeContext';
import { ProfileDataContext } from './ProfileDataContext';

type GetTimelineElementsParsedProps = {
  timelineValues: any;
  context: ContextType;
};

type GetWarningDataParsedProps = {
  context: ContextType;
};

export type ContextType = 'inbound' | 'outbound';

export type Status = 'idle' | 'loading' | 'success' | 'error';

type OutboundTotalStatusDataResponse = {
  waiting_labels: number;
  holded: number;
  picking: number;
  picked: number;
  packing: number;
  packed: number;
  in_transit: number;
  dispatched: number;
  returned: number;
  delivered: number;
  canceled: number;
  partial_check: number;
};

type InboundTotalStatusDataResponse = {
  sent: number;
  received: number;
  partial: number;
  checked: number;
  stored: number;
  partial_check: number;
};

export type ItemDetailsResponse = {
  page: number;
  per_page: number;
  total: number;
  data: {
    name: string;
    order_id: number;
    [key: string]: string | number;
  }[];
};

type InboundItemsDetailsState = {
  timelineData: {
    partialConference: ItemDetailsResponse | null;
  };
  warningData: {
    shipmentsWithDivergence: ItemDetailsResponse | null;
    blockedItems: ItemDetailsResponse | null;
  };
};

type InboundStatus = {
  itemsDetails: Status;
  timelineElements: Status;
  warningData: Status;
};

type InboundData = {
  itemsDetails: InboundItemsDetailsState;
  timelineElements: TimelineMapperData[];
  warningData: WarningMapperData[];
  warningQuantity: number;
};

type OutboundStatus = {
  timelineElements: Status;
  warningData: Status;
};

type OutboundData = {
  timelineElements: TimelineMapperData[];
  warningData: WarningMapperData[];
  warningQuantity: number;
};

type DashboardStatusState = {
  inbound: InboundStatus;
  outbound: OutboundStatus;
};

type DashboardDataState = {
  inbound: InboundData;
  outbound: OutboundData;
};

interface DashboardDataContextData {
  generalStatus: Status;

  dashboardStatus: DashboardStatusState;
  dashboardData: DashboardDataState;

  getInboundTimelineItemsDetails: () => Promise<void>;

  getOutboundTimelineData: () => Promise<void>;
  getInboundTimelineData: () => Promise<void>;

  getOutboundWarningData: () => Promise<void>;
  getInboundWarningData: () => Promise<void>;

  getAllDashboardData: () => Promise<void>;
}

export const DashboardDataContext = createContext(
  {} as DashboardDataContextData
);

export const DashboardDataContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const storeSelected = useContextSelector(
    ProfileDataContext,
    ctx => ctx.profileData.storeSelected
  );
  const datesRange = useContextSelector(
    DatesRangeContext,
    ctx => ctx.datesRange
  );

  const [dashboardStatus, setDashboardStatus] = useState<DashboardStatusState>({
    inbound: {
      itemsDetails: 'idle',
      timelineElements: 'idle',
      warningData: 'idle',
    },
    outbound: {
      timelineElements: 'idle',
      warningData: 'idle',
    },
  });

  const isLoading =
    Object.values(dashboardStatus.outbound).every(
      status => status === 'loading'
    ) ||
    Object.values(dashboardStatus.inbound).every(
      status => status === 'loading'
    );

  const isSuccess =
    Object.values(dashboardStatus.outbound).every(
      status => status === 'success'
    ) ||
    Object.values(dashboardStatus.inbound).every(
      status => status === 'success'
    );

  const isError =
    Object.values(dashboardStatus.outbound).every(
      status => status === 'error'
    ) ||
    Object.values(dashboardStatus.inbound).every(status => status === 'error');

  const generalStatus: Status =
    (isLoading && 'loading') ||
    (isSuccess && 'success') ||
    (isError && 'error') ||
    'idle';

  const [dashboardData, setDashboardData] = useState<DashboardDataState>({
    inbound: {
      itemsDetails: {
        timelineData: {
          partialConference: null,
        },
        warningData: {
          shipmentsWithDivergence: null,
          blockedItems: null,
        },
      },
      timelineElements: [],
      warningData: [],
      warningQuantity: 0,
    },
    outbound: {
      timelineElements: [],
      warningData: [],
      warningQuantity: 0,
    },
  });

  const getInboundTimelineItemsDetails = async () => {
    const params = {
      store_id: storeSelected.id,
      initial_date: datesRange.startDate,
      final_date: datesRange.endDate,
    };

    try {
      setDashboardStatus(state => ({
        ...state,
        inbound: {
          ...state.inbound,
          itemsDetails: 'loading',
        },
      }));

      const { status: statusOfPartialConference, data: partialConference } =
        await axios.get<ItemDetailsResponse>(
          `${apiBaseURL}/inbound/total-status/partial`,
          { params }
        );

      const {
        status: statusOfShipmentsWithDivergence,
        data: shipmentsWithDivergence,
      } = await axios.get<ItemDetailsResponse>(
        `${apiBaseURL}/inbound/orders/divergence`,
        { params }
      );

      const { status: statusOfBlockedItems, data: blockedItems } =
        await axios.get<ItemDetailsResponse>(
          `${apiBaseURL}/inbound/orders/damaged`,
          { params }
        );

      if (
        [HTTP_RESPONSES.OK].includes(statusOfPartialConference) &&
        [HTTP_RESPONSES.OK].includes(statusOfShipmentsWithDivergence) &&
        [HTTP_RESPONSES.OK].includes(statusOfBlockedItems)
      ) {
        setDashboardData(state => ({
          ...state,
          inbound: {
            ...state.inbound,
            itemsDetails: {
              timelineData: {
                partialConference,
              },
              warningData: {
                blockedItems,
                shipmentsWithDivergence,
              },
            },
          },
        }));

        setDashboardStatus(state => ({
          ...state,
          inbound: {
            ...state.inbound,
            itemsDetails: 'success',
          },
        }));
      }
    } catch (error) {
      setDashboardStatus(state => ({
        ...state,
        inbound: {
          ...state.inbound,
          itemsDetails: 'error',
        },
      }));
      toast.error(ERROR_MESSAGES.inboundItemsDetails);
    }
  };

  const getTimelineElementsParsed = ({
    timelineValues,
    context,
  }: GetTimelineElementsParsedProps) => {
    const dateIsToday =
      getStartAndEndDateBeingToday().startDate === datesRange.startDate;

    const timelineElements = TimelineMapper.filter(
      item => item.context === context
    )[0];
    let totalOrders = 0;
    let totalFirstStageOrders = 0;

    const finalElements: TimelineMapperData[] = [];
    const typesToSum = ['waiting_labels', 'holded', 'picking', 'picked'];
    const monthTypesToSum = ['waiting_labels', 'holded', 'picking'];
    if (!dateIsToday) timelineValues.data.received = 0;
    timelineValues.data.partial_check = 0;

    timelineElements.data.forEach(element => {
      const response = timelineValues.data;
      const dates = element.date;
      const searchFor = dateIsToday ? 'now' : 'month';
      const value = response[element.ref];

      if (
        response.hasOwnProperty(element.ref) &&
        dates.findIndex(elementRef => elementRef === searchFor) != -1
      ) {
        element.value = value;
        finalElements.push(element);
      }

      if (
        searchFor === 'month' &&
        monthTypesToSum.findIndex(type => type == element.ref) != -1
      ) {
        totalFirstStageOrders += value;
      }

      if (typesToSum.findIndex(type => type == element.ref) != -1) {
        totalOrders += value;
      }
    });

    // insert the tooltip of picked itens after the sum of all previous orders
    const pickedIndex = finalElements.findIndex(
      element => element.ref == 'picked'
    );
    if (pickedIndex != -1) {
      finalElements[
        pickedIndex
      ].tooltip = `<b>Pedidos separados:</b> ${finalElements[pickedIndex].value}<br/><b>Referente a:</b> ${totalOrders}`;
    }
    // insert the monthtypes sum if its month and outbound
    if (totalFirstStageOrders) finalElements[0].value = totalFirstStageOrders;
    const warningElement = timelineElements.data.filter(
      element => element.ref == 'warning'
    )[0];
    warningElement.value = dashboardData[context].warningQuantity;
    // identify where is the first success component in timeline and add the warning component in the right spot
    const indexSuccess = finalElements.findIndex(
      element => element.color === 'success'
    );
    finalElements.splice(indexSuccess, 0, warningElement);

    return finalElements;
  };

  const getOutboundTimelineData = async () => {
    const params = {
      store_id: storeSelected.id,
      initial_date: datesRange.startDate,
      final_date: datesRange.endDate,
    };

    try {
      setDashboardStatus(state => ({
        ...state,
        outbound: {
          ...state.outbound,
          timelineElements: 'loading',
        },
      }));

      const { status, data: outboundTimelineValues } = await axios.get<{
        data: OutboundTotalStatusDataResponse;
      }>(`${apiBaseURL}/outbound/total-status`, { params });

      const outboundTimelineElements = getTimelineElementsParsed({
        timelineValues: outboundTimelineValues,
        context: 'outbound',
      });

      if ([HTTP_RESPONSES.OK].includes(status)) {
        setDashboardData(state => ({
          ...state,
          outbound: {
            ...state.outbound,
            timelineElements: outboundTimelineElements,
          },
        }));

        setDashboardStatus(state => ({
          ...state,
          outbound: {
            ...state.outbound,
            timelineElements: 'success',
          },
        }));
      }
    } catch (error) {
      setDashboardStatus(state => ({
        ...state,
        outbound: {
          ...state.outbound,
          timelineElements: 'error',
        },
      }));
      toast.error(ERROR_MESSAGES.outboundTimelineData);
    }
  };

  const getInboundTimelineData = async () => {
    const params = {
      store_id: storeSelected.id,
      initial_date: datesRange.startDate,
      final_date: datesRange.endDate,
    };

    try {
      setDashboardStatus(state => ({
        ...state,
        inbound: {
          ...state.inbound,
          timelineElements: 'loading',
        },
      }));

      const { status, data: inboundTimelineValues } = await axios.get<{
        data: InboundTotalStatusDataResponse;
      }>(`${apiBaseURL}/inbound/total-status`, { params });

      const inboundTimelineElements = getTimelineElementsParsed({
        timelineValues: inboundTimelineValues,
        context: 'inbound',
      });

      if ([HTTP_RESPONSES.OK].includes(status)) {
        setDashboardData(state => ({
          ...state,
          inbound: {
            ...state.inbound,
            timelineElements: inboundTimelineElements,
          },
        }));
        setDashboardStatus(state => ({
          ...state,
          inbound: {
            ...state.inbound,
            timelineElements: 'success',
          },
        }));
      }
    } catch (error) {
      setDashboardStatus(state => ({
        ...state,
        inbound: {
          ...state.inbound,
          timelineElements: 'error',
        },
      }));
      toast.error(ERROR_MESSAGES.inboundTimelineData);
    }
  };

  const getTimelineData = async () => {
    getOutboundTimelineData();
    getInboundTimelineData();
  };

  type WarningDataResponse = {
    warningQuantity: number;
    warningData: WarningMapperData[];
  };

  const getWarningDataParsed = async ({
    context,
  }: GetWarningDataParsedProps) => {
    let totalNumberOfWarnings = 0;

    const baseWarningData = WarningMapper.filter(
      item => item.context === context
    )[0].data;

    const initialValuesResponse: WarningDataResponse = {
      warningQuantity: 0,
      warningData: [],
    };
    let response: WarningDataResponse = initialValuesResponse;

    const promisesToGetTotalItemQuantity = baseWarningData.map(warningItem => {
      try {
        return axios.get<AxiosResponse<{ total: number }>>(
          `${apiBaseURL}/${context}${warningItem.apiUrl}`,
          {
            params: {
              store_id: storeSelected.id,
              initial_date: datesRange.startDate,
              final_date: datesRange.endDate,
            },
          }
        );
      } catch {}
    });

    await Promise.all(promisesToGetTotalItemQuantity)
      .then(promisesResults => {
        const finalWarningDataData: WarningMapperData[] = [];

        promisesResults.forEach(promiseResult => {
          const {
            data: { total: totalItemQuantity },
          } = promiseResult.data;

          const currentItemFromBaseWarningData = baseWarningData.find(
            warningItem => promiseResult.config.url.includes(warningItem.apiUrl)
          );
          currentItemFromBaseWarningData.totalQuantity = totalItemQuantity;

          finalWarningDataData.push(currentItemFromBaseWarningData);

          totalNumberOfWarnings += totalItemQuantity;
        });

        response.warningQuantity = totalNumberOfWarnings;
        response.warningData = finalWarningDataData;
      })
      .catch(() => {
        response = initialValuesResponse;
      });

    return response;
  };

  const getOutboundWarningData = async () => {
    try {
      const { warningData, warningQuantity } = await getWarningDataParsed({
        context: 'outbound',
      });

      setDashboardData(state => ({
        ...state,
        outbound: {
          ...state.outbound,
          warningData,
          warningQuantity,
        },
      }));

      setDashboardStatus(state => ({
        ...state,
        outbound: {
          ...state.outbound,
          warningData: 'success',
        },
      }));
    } catch (error) {
      setDashboardStatus(state => ({
        ...state,
        outbound: {
          ...state.outbound,
          warningData: 'error',
        },
      }));
      toast.error(ERROR_MESSAGES.outboundWarningQuantityData);
    }
  };

  const getInboundWarningData = async () => {
    try {
      const { warningData, warningQuantity } = await getWarningDataParsed({
        context: 'inbound',
      });

      setDashboardData(state => ({
        ...state,
        inbound: {
          ...state.inbound,
          warningData,
          warningQuantity,
        },
      }));

      setDashboardStatus(state => ({
        ...state,
        inbound: {
          ...state.inbound,
          warningData: 'success',
        },
      }));
    } catch (error) {
      setDashboardStatus(state => ({
        ...state,
        inbound: {
          ...state.inbound,
          warningData: 'error',
        },
      }));
      toast.error(ERROR_MESSAGES.inboundWarningQuantityData);
    }
  };

  const getWarningData = async () => {
    getOutboundWarningData();
    getInboundWarningData();
  };

  const getAllDashboardData = async () => {
    getInboundTimelineItemsDetails();

    getTimelineData();
    getWarningData();
  };

  useEffect(() => {
    getAllDashboardData();
  }, [storeSelected.id, datesRange]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardDataContext.Provider
      value={{
        generalStatus,

        dashboardStatus,
        dashboardData,

        getInboundTimelineItemsDetails,

        getOutboundTimelineData,
        getInboundTimelineData,

        getOutboundWarningData,
        getInboundWarningData,

        getAllDashboardData,
      }}
    >
      {children}
    </DashboardDataContext.Provider>
  );
};
