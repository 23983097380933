import { ErrorComponent } from '@Estoca/ui';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';

import { DashboardApp } from './DashboardApp';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: DashboardApp,
  errorBoundary(err, info, props) {
    return <ErrorComponent error={err} />;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
