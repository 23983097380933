const SUPPORT_DESCRIPTION = 'por favor contatar o suporte!';
const ERROR_DESCRIPTION = `Caso o erro persista, ${SUPPORT_DESCRIPTION}`;

export const ERROR_MESSAGES = {
  inboundWarningQuantityData: `Erro ao obter os dados de atenção de Armazenagem. ${ERROR_DESCRIPTION}`,
  outboundWarningQuantityData: `Erro ao obter os dados de atenção de Vendas. ${ERROR_DESCRIPTION}`,

  inboundItemsDetails: `Erro ao obter os dados de detalhes dos itens de Armazenagem. ${ERROR_DESCRIPTION}`,

  inboundTimelineData: `Erro ao obter os dados da linha do tempo de Armazenagem. ${ERROR_DESCRIPTION}`,
  outboundTimelineData: `Erro ao obter os dados da linha do tempo de Outbound. ${ERROR_DESCRIPTION}`,

  inboundSlaChartData: `Erro ao obter os dados de SLA de Armazenagem geral. ${ERROR_DESCRIPTION}`,

  outboundSlaChartData: `Erro ao obter os dados de SLA de Vendas geral. ${ERROR_DESCRIPTION}`,
  outboundStatesSlaChartData: `Erro ao obter os dados de SLA de Vendas por estado. ${ERROR_DESCRIPTION}`,
} as const;
