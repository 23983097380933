import { type DatesRange } from '../context/DatesRangeContext';

const todayDate = new Date();

const getFormattedDate = (date: Date) => {
  const timezoneOffset = date.getTimezoneOffset();
  const dateWithoutTimezoneOffset = new Date(
    date.getTime() - timezoneOffset * 60 * 1000
  );

  const formattedDate = dateWithoutTimezoneOffset.toISOString().split('T')[0]; // YYYY-MM-DD

  return formattedDate;
};

export const getTheRangeDatesBetweenTodayAndLastMonth = (): DatesRange => {
  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);

  return {
    startDate: getFormattedDate(lastMonth),
    endDate: getFormattedDate(todayDate),
  };
};

export const getStartAndEndDateBeingToday = (): DatesRange => ({
  startDate: getFormattedDate(todayDate),
  endDate: getFormattedDate(todayDate),
});

export const getTheRangeDatesBetweenTodayAndLastWeek = (): DatesRange => {
  const lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 7);

  return {
    startDate: getFormattedDate(lastWeek),
    endDate: getFormattedDate(todayDate),
  };
};
